.ant-menu {
  box-shadow:
    0 9px 28px 8px rgba(12, 62, 134, 0.05),
    0 6px 16px rgba(12, 62, 134, 0.05),
    0 3px 6px -4px rgba(12, 62, 134, 0.08);
  border-radius: 2px;

  & .separate-line {
    border-top: 1px;
  }

  & > .ant-menu-sub {
    box-shadow: none;
  }
}

.ant-menu-vertical {
  border-right: none;
}
