@import "src/styles/color";
@import "src/app/shared/components/button/styles";
@import "src/styles/breakpoints";

.ant-modal-mask {
  background-color: rgba(38, 38, 38, 0.45) !important;
}

.ant-modal-content {
  background: var(--page-bg) !important;
  opacity: 1;
  box-shadow: 0 9px 28px 8px rgba(12, 62, 134, 0.05), 0 6px 16px rgba(12, 62, 134, 0.05),
    0 3px 6px -4px rgba(12, 62, 134, 0.08);
  border-radius: 16px !important;
  z-index: 1001;
  overflow: hidden;

  @media (max-width: $md-width) {
    margin: 0 8px;
  }

  @media (max-width: $xs-width) {
    margin: 0;
  }

  .ant-modal-close {
    color: var(--text-secondary);

    &:hover {
      color: var(--grey-middle);
    }
  }

  .ant-modal-header {
    border-radius: 16px 16px 0 0;
    background-color: var(--page-bg);
  }

  .ant-modal-body {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: var(--fs-14) !important;
    line-height: var(--lh-14) !important;
    color: var(--text-primary) !important;
  }
}

.ant-modal-title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: var(--fs-16) !important;
  line-height: var(--lh-16) !important;
  color: var(--text-primary) !important;
  padding-right: 20px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  @extend .ant-modal-title;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  @extend .ant-modal-body;
}

.ant-modal-confirm-body .anticon .ant-modal-confirm-title .ant-modal-confirm-content {
  @extend .ant-modal-body;
}

.ant-modal-confirm-btns:first-child {
  @extend .secondary;
}

.ant-modal-confirm-btns .ant-btn-primary {
  @extend .primary;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: var(--primary);
}

.vi-enabled {
  .ant-modal-mask {
    background-color: transparent !important;
  }

  .ant-modal-content {
    border-radius: 0 !important;
    border: 1px solid var(--main-color);

    .ant-modal-header {
      border-radius: 0;
      border-color: var(--main-color);
    }
  }
}
