@import "color";

.ant-picker {
  border: 1px solid var(--grey-middle);
  box-sizing: border-box;
  border-radius: 60px;
  padding: 7px 11px;
  background: var(--page-bg);
  font-size: var(--fs-14);

  &:hover {
    border-color: var(--secondary);
  }

  & .ant-picker-input > input {
    font-size: var(--fs-14);
    color: var(--text-primary);

    &::placeholder {
      color: var(--text-secondary);
    }
  }

  &.ant-picker-focused {
    border: 1px solid var(--primary);
    box-shadow: 0 0 4px rgba(24, 144, 255, 0.5);
  }

  & .ant-picker-suffix {
    color: var(--text-secondary);
  }
}

.ant-picker-dropdown {
  background: var(--page-bg);
  box-shadow:
    0 9px 28px 8px rgba(12, 62, 134, 0.05),
    0 6px 16px rgba(12, 62, 134, 0.05),
    0 3px 6px -4px rgba(12, 62, 134, 0.08);
  border-radius: 2px;
  color: var(--text-primary);

  .ant-picker-panel-container {
    background-color: var(--page-bg);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--primary);

  &::before {
    display: none;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--primary);
}

.ant-picker-header-view button {
  font-weight: 600;

  &:hover {
    color: var(--primary);
  }
}

.ant-picker-cell-in-view {
  color: var(--text-primary) !important;
  background-color: var(--page-bg);
}

.ant-picker-today-btn {
  color: var(--primary);
  font-weight: 600;

  &:hover {
    color: var(--secondary);
  }
}

.ant-picker-header {
  color: var(--text-primary);
  border-color: var(--grey-light);
}

.ant-picker-header-next-btn {
  display: flex;
  align-items: center;

  .ant-picker-next-icon {
    transform: none;
    width: 16px;
    height: 16px;
    margin: auto;
    padding: 0;

    &::before {
      border: none;
      width: 16px;
      height: 16px;
      background-image: url("../assets/icons/arrowNextIcon.svg");
    }
  }
}

.ant-picker-header-prev-btn {
  display: flex;
  align-items: center;

  .ant-picker-prev-icon {
    transform: none;
    width: 16px;
    height: 16px;
    margin: auto;
    padding: 0;

    &::before {
      border: none;
      width: 16px;
      height: 16px;
      background-image: url("../assets/icons/arrowPrevIcon.svg");
    }
  }
}

.ant-picker-header-super-next-btn {
  display: flex;
  align-items: center;

  .ant-picker-super-next-icon {
    transform: none;
    width: 16px;
    height: 16px;
    margin: auto;
    padding: 0;

    &::after {
      border: none;
    }

    &::before {
      border: none;
      width: 16px;
      height: 16px;
      background-image: url("../assets/icons/arrowDoubleNextIcon.svg");
      color: var(--text-primary);
    }
  }
}

.ant-picker-header-super-prev-btn {
  display: flex;
  align-items: center;

  .ant-picker-super-prev-icon {
    transform: none;
    width: 16px;
    height: 16px;
    margin: auto;
    padding: 0;

    &::after {
      border: none;
    }

    &::before {
      border: none;
      width: 16px;
      height: 16px;
      background-image: url("../assets/icons/arrowDoublePrevIcon.svg");
    }
  }
}

.ant-picker-panel-container {
  box-shadow: none;
}

.ant-picker-cell {
  color: var(--grey-dark);

  &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background-color: var(--grey-ultra-light);
    color: var(--main-color);
  }

  &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start,
  &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
    .ant-picker-cell-inner {
      color: var(--page-bg);

      &::after {
        background: var(--primary) !important;
      }
    }
  }

  &.ant-picker-cell-range-hover-end,
  &.ant-picker-cell-range-hover-start {
    &::after {
      border-color: var(--primary) !important;
    }
  }

  &::after {
    border-color: var(--primary) !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--primary);
  color: var(--page-bg);
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background-color: var(--primary);
  color: var(--page-bg);
}

.ant-picker-range .ant-picker-active-bar {
  display: none;
}

.vi-enabled {
  .ant-picker {
    border-radius: 0;
  }

  .ant-picker-separator {
    color: var(--main-color);
  }

  .ant-picker-dropdown {
    border: 1px solid var(--main-color);

    .ant-picker-panel {
      border-color: var(--main-color);
    }

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-panel-container {
      background-color: var(--page-bg);
    }

    .ant-picker-cell-in-view {
      &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
      &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before {
        background: var(--primary-dark);
      }

      &.ant-picker-cell-in-range::before {
        border-color: var(--main-color);
        border-style: solid;
        border-width: 1px 0;
        background: var(--primary-light);
      }
    }
  }
}
