@import "src/styles/color";

.ant-select-dropdown {
  color: var(--text-primary);
  background-color: var(--page-bg);
  padding: 0;
  box-shadow:
    0 9px 28px 8px rgba(12, 62, 134, 0.05),
    0 6px 16px rgba(12, 62, 134, 0.05),
    0 3px 6px -4px rgba(12, 62, 134, 0.08);

  .ant-select-item {
    color: var(--text-primary);
    font-size: var(--fs-14);
  }

  .ant-select-item-option-selected {
    background-color: var(--primary-light);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--grey-ultra-light);
  }

  .area:hover {
    background-color: var(--grey-ultra-light);
  }

  .area-selected {
    background-color: var(--primary-light);

    .ant-select-item-option-state {
      color: var(--secondary);
    }
  }
}

.vi-enabled {
  .ant-select-dropdown {
    border: 1px solid var(--main-color);
  }
}
