@import "color";

:root {
  --font-size: 14px;
  --font-family: montserrat, sans-serif;
  --letter-spacing: normal;
  --main-color: rgba(0, 0, 0, 0.85);
  --page-bg: #{$white};
  --primary: #{$primary-blue};
  --primary-light: #{$light-blue};
  --primary-dark: #{$dark-blue};
  --primary-bg: #{$background};
  --secondary: #{$secondary-blue};
  --accent: #{$orange};
  --text-primary: #{$text-primary};
  --text-secondary: #{$text-secondary};
  --text-bold-secondary: #{$text-bold-secondary};
  --grey: #{$grey};
  --grey-dark: #{$grey-dark};
  --grey-middle: #{$grey-middle};
  --grey-light: #{$grey-light};
  --grey-ultra-light: #{$grey-ultra-light};
  --linear-primary: #{$linear};
  --linear-secondary: #{$linear-blue};

  // Success palette
  --success: #{$success};
  --success-middle: #{$success-middle};
  --success-light: #{$success-light};

  // Error palette
  --error: #{$error};
  --error-middle: #{$error-middle};
  --error-light: #{$error-light};

  // Error palette
  --warning: #{$warning};
  --warning-middle: #{$warning-middle};
  --warning-light: #{$warning-light};

  // Social network active
  --vk-active: #{$vk-active};
  --fb-active: #{$fb-active};

  // Font sizes and line heights
  // 24px/32px
  --fs-24: 1.7143rem;
  --lh-24: 1.3333;

  // 20px/28px
  --fs-20: 1.4286rem;
  --lh-20: 1.4;

  // 16px/24px
  --fs-16: 1.1429rem;
  --lh-16: 1.5;

  // 14px/20px(b:22px)
  --fs-14: 1rem;
  --lh-14: 1.4286;
  --lh-14-b: 1.5715;

  // 12px/20px(s:16px)
  --fs-12: 0.8571rem;
  --lh-12: 1.67;
  --lh-12-s: 1.3333;
}

:root.vi-enabled {
  // font size
  &.font-size-small {
    --font-size: 19px;
  }

  &.font-size-medium {
    --font-size: 24px;
  }

  &.font-size-large {
    --font-size: 29px;
  }

  // theme
  &.theme-black {
    --main-color: #{$white};
    --page-bg: #{$black};
  }

  &.theme-white {
    --main-color: #{$black};
    --page-bg: #{$white};
  }

  &.theme-blue {
    --main-color: #14305e;
    --page-bg: #9dd1ff;
  }

  // letter spacing
  &.letter-spacing-medium {
    --letter-spacing: 0.05em !important;
  }

  &.letter-spacing-large {
    --letter-spacing: 0.15em !important;
  }

  // font family
  &.font-type-sans-serif {
    --font-family: arial, sans-serif;
  }

  &.font-type-serif {
    --font-family: "Times New Roman", serif;
  }

  --primary: var(--main-color);
  --primary-light: var(--page-bg);
  --primary-dark: var(--main-color);
  --primary-bg: var(--page-bg);
  --secondary: var(--main-color);
  --accent: var(--main-color);
  --text-primary: var(--main-color);
  --text-secondary: var(--main-color);
  --grey-dark: var(--main-color);
  --grey-middle: var(--main-color);
  --grey-light: var(--main-color);
  --grey-ultra-light: var(--page-bg);
  --linear-primary: var(--page-bg);
  --linear-secondary: var(--page-bg);

  // Success palette
  --success: var(--main-color);
  --success-middle: var(--main-color);
  --success-light: var(--page-bg);

  // Error palette
  --error: var(--main-color);
  --error-middle: var(--main-color);
  --error-light: var(--page-bg);

  // Error palette
  --warning: var(--main-color);
  --warning-middle: var(--main-color);
  --warning-light: var(--page-bg);

  // Font sizes and line heights
  --lh-24: 1.15;
  --lh-20: 1.15;
  --lh-16: 1.15;
  --lh-14: 1.15;
  --lh-14-b: 1.15;
  --lh-12: 1.15;
  --lh-12-s: 1.15;
}
