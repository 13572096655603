@import "src/styles/breakpoints";

.demoAlert {
  font-size: var(--fs-24);
  line-height: var(--lh-24);
  background: var(--accent);
  color: var(--page-bg);
  font-weight: 600;
  text-align: center;
  padding: 19px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (max-width: $md-width) {
    position: initial;
  }
}

.vi-enabled {
  .demoAlert {
    top: 180px;
  }

  &.font-size-medium .demoAlert {
    top: 200px;
  }

  &.font-size-large .demoAlert {
    top: 220px;
  }
}
