@import "src/styles/color";

.ant-btn {
  font-size: var(--fs-14) !important;
  line-height: var(--lh-14);
  font-weight: 600 !important;
  height: 40px !important;
  min-width: 40px !important;
  border-style: solid;
  box-shadow: none !important;

  &::before {
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }

  &.ant-btn-icon-only {
    border-width: 1px !important;
  }

  &:not(.ant-btn-icon-only) {
    padding-left: 22px;
    padding-right: 22px;
    border-width: 2px !important;
  }

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    color: var(--text-secondary) !important;
    border-color: var(--grey-middle) !important;
    background: var(--grey-ultra-light) !important;
  }
}

.ant-btn.primary {
  &:not(:disabled) {
    background: var(--primary);
    color: var(--page-bg);
    border-color: var(--primary);

    &:hover,
    &:focus {
      background: var(--secondary);
      color: var(--page-bg);
      border-color: var(--secondary);
    }

    &:active {
      background: var(--primary-dark);
      color: var(--page-bg);
      border-color: var(--primary-dark);
    }
  }
}

.ant-btn.danger {
  &:not(:disabled) {
    background: var(--error);
    color: var(--page-bg);
    border-color: var(--error);

    &:hover,
    &:focus {
      background: var(--error-middle);
      color: var(--page-bg);
      border-color: var(--error-middle);
    }

    &:active {
      background: var(--error-middle);
      color: var(--page-bg);
      border-color: var(--error-middle);
    }
  }
}

.ant-btn.secondary {
  border-style: solid;

  &.ant-btn-icon-only {
    border-width: 1px;
  }

  &:not(.ant-btn-icon-only) {
    border-width: 2px;
  }

  &:not(:disabled) {
    background: transparent;
    color: var(--text-primary);
    border-color: var(--grey-middle);

    &:hover {
      background: transparent;
      color: var(--primary);
      border-color: var(--grey-middle);
    }

    &:active,
    &:focus {
      background: transparent;
      color: var(--primary-dark);
      border-color: var(--primary-dark);
    }
  }
}

.ant-btn.text {
  background: transparent;
  border: none;
  box-shadow: none;

  &:not(:disabled) {
    color: var(--text-primary);

    &:hover {
      background: #fafafa;
      color: var(--text-primary);
      border: none;
    }

    &:active,
    &:focus {
      background: var(--grey-light);
      color: var(--text-primary);
      border: none;
    }
  }
}

.ant-btn.link {
  background: transparent;
  border: none;
  box-shadow: none;

  &:not(:disabled) {
    color: var(--primary);

    &:hover {
      background: transparent;
      color: var(--secondary);
      border: none;
    }

    &:active,
    &:focus {
      background: transparent;
      color: var(--primary-dark);
      border: none;
    }
  }
}

.ant-btn.outline {
  &.has-icon:disabled {
    background: transparent;
  }

  &:not(:disabled) {
    background: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);

    &:hover {
      background: transparent;
      color: var(--secondary);
      border-color: var(--secondary);
    }

    &:active,
    &:focus {
      background: transparent;
      color: var(--primary-dark);
      border-color: var(--primary-dark);
    }
  }
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 10px;
}

.ant-btn.mobileLink {
  width: 100%;
  height: 48px !important;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  border: none;
  box-shadow: 0px 9px 28px 8px rgba(12, 62, 134, 0.05), 0px 6px 16px rgba(12, 62, 134, 0.05),
    0px 3px 6px -4px rgba(12, 62, 134, 0.08) !important;
  border-radius: 16px;
  background: var(--page-bg);

  &:not(:disabled) {
    color: var(--primary);

    &:hover {
      background: transparent;
      color: var(--secondary);
      border: none;
    }

    &:active,
    &:focus {
      background: transparent;
      color: var(--primary-dark);
      border: none;
    }
  }
}

.vi-enabled {
  .ant-btn:not(.ant-btn-icon-only) {
    height: auto !important;
    padding: 4px 16px !important;

    &.ant-btn-round {
      border-radius: 0 !important;
    }
  }

  .ant-btn.mobileLink {
    box-shadow: none !important;
    border: 1px solid var(--main-color);

    &:not(:disabled):hover {
      border: 1px solid var(--main-color);
    }
  }
}
