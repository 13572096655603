.ant-alert {
  color: var(--text-primary);

  .ant-alert-message {
    color: var(--text-primary);
    font-size: var(--fs-14);
    line-height: var(--lh-14);
  }

  &.ant-alert-info {
    border-color: var(--primary);
    background-color: var(--primary-light);
  }
}
