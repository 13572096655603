@import "src/styles/color";

.loader {
  display: inline-block;
  animation: 2s rotate infinite linear;
  line-height: 0;

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
  }

  & circle {
    &:first-child {
      fill: transparent;
      stroke: var(--grey-ultra-light);
      stroke-width: 6px;
      stroke-linecap: round;
    }

    &:last-child {
      fill: transparent;
      stroke: var(--primary);
      stroke-width: 6px;
      stroke-linecap: round;
      stroke-dasharray: 2.3525em 0.4705em;
      animation: 1.5s stroke-dash-array infinite linear;
    }
  }
}

.vi-enabled.without-images {
  .loader {
    animation: none;

    &::after {
      content: 'Загрузка';
      display: flex;
      align-items: center;
      font-size: var(--fs-14);
      height: 100%;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes stroke-dash-array {
  from {
    stroke-dasharray: 2.3525em 0.4705em;
    stroke-dashoffset: 0;
  }

  10% {
    stroke-dasharray: 2.3525em 0.4705em;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 0.001em 2.825em;
    stroke-dashoffset: -2.826em;
  }

  60% {
    stroke-dasharray: 0.001em 2.825em;
  }

  to {
    stroke-dasharray: 2.3525em 0.4705em;
    stroke-dashoffset: -2.826em;
  }
}
