@import "src/styles/color";

.ant-select {
  color: var(--text-primary);
  font-size: var(--fs-14);

  &.ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-select-selection-placeholder {
    color: var(--grey-dark);
  }

  .ant-select-arrow {
    color: var(--grey-dark);
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--page-bg);
  border: 1px solid var(--grey-middle);
  box-sizing: border-box;
  border-radius: 60px;
  padding: 4px 12px;

  &:hover {
    border-color: var(--secondary);
  }
}

.ant-select-single .ant-select-selection-placeholder {
  padding: 0 5px;
}

.ant-select-single.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector.ant-select-selector:hover {
  border-color: var(--grey-middle);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary);
  box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 28px;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

.vi-enabled {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 7px 11px;
    height: auto;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
  }
}
