.common {
  margin: 0;
}

.h1 {
  font-size: var(--fs-20);
  font-weight: 600;
  line-height: var(--lh-20);
  letter-spacing: 0.01em;
}

.h2 {
  font-size: var(--fs-24);
  font-weight: 600;
  line-height: var(--lh-24);
  letter-spacing: 0.01em;
}

.h3 {
  font-size: var(--fs-20);
  font-weight: 600;
  line-height: var(--lh-20);
  letter-spacing: 0.01em;
}

.h4 {
  font-size: var(--fs-16);
  font-weight: 600;
  line-height: var(--lh-16);
}

.body {
  font-size: var(--fs-14);
  font-weight: 400;
  line-height: var(--lh-14);
}

.bodyBold {
  font-size: var(--fs-14);
  font-weight: 600;
  line-height: var(--lh-14-b);
}

.caption {
  font-size: var(--fs-12);
  font-weight: 400;
  line-height: var(--lh-12);
}

.captionBold {
  font-size: var(--fs-12);
  font-weight: 600;
  line-height: var(--lh-12);
}

.button {
  font-size: var(--fs-14);
  font-weight: 600;
  line-height: var(--lh-14);
}

.input {
  font-size: var(--fs-14);
  font-weight: 400;
  line-height: var(--lh-14);
}

.label {
  font-size: var(--fs-12);
  font-weight: 400;
  line-height: var(--lh-12-s);
}

:global(.vi-enabled) {
  &:global(.letter-spacing-medium) {
    .h1,
    .h2,
    .h3 {
      letter-spacing: 0.05em;
    }
  }

  &:global(.letter-spacing-large) {
    .h1,
    .h2,
    .h3 {
      letter-spacing: 0.15em;
    }
  }
}
