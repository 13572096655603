@import "src/styles/breakpoints";

.TechnicalWorkingAlert {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10004;
  height: 58px;

  &.is-impersonation {
    top: 58px;
  }

  @media (max-width: $md-width) {
    position: initial;
    display: flex;
    flex-direction: column;

    & .ant-alert-content {
      text-align: center;
    }
  }
}
