@import "src/styles/breakpoints";

.is-password-temporary-alert {
  font-size: var(--fs-16) !important;
  line-height: var(--lh-16) !important;
  background: var(--accent);
  color: var(--page-bg);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 9;

  @media (max-width: $md-width) {
    position: initial;
    height: auto !important;
  }
}

.vi-enabled {
  .is-password-temporary-alert {
    height: 100px;
    top: 180px;
  }

  &.font-size-medium .is-password-temporary-alert {
    height: 125px;
    top: 200px;
  }

  &.font-size-large .is-password-temporary-alert {
    height: 150px;
    top: 220px;
  }
}
