@import "src/styles/breakpoints";

.impersonationAlert {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (max-width: $md-width) {
    position: initial;
    display: flex;
    flex-direction: column;

    & .ant-alert-content {
      text-align: center;
    }
  }
}
