@import "color";

.ant-dropdown {
  background: var(--page-bg);
  box-shadow: 0 9px 28px 8px rgba(12, 62, 134, 0.05), 0 6px 16px rgba(12, 62, 134, 0.05),
    0 3px 6px -4px rgba(12, 62, 134, 0.08);
  border-radius: 2px;
  color: var(--text-primary);
}

.ant-dropdown .ant-menu-item-active {
  background-color: var(--grey-ultra-light);

  &:hover {
    color: var(--text-primary);
  }
}

.ant-dropdown .ant-menu-item-selected {
  font-weight: bold;
  background-color: var(--grey-ultra-light);
  color: var(--text-primary);

  > .ant-menu-submenu-title {
    color: var(--text-primary);

    > .ant-menu-submenu-arrow {
      color: var(--text-primary);
    }
  }
}

.ant-menu-submenu {
  &:hover {
    background-color: var(--primary-bg);
  }

  > .ant-menu-submenu-title {
    color: var(--text-primary);

    &:hover {
      color: var(--text-primary);

      > .ant-menu-submenu-arrow {
        color: var(--text-primary);
      }
    }
  }
}

.ant-menu-submenu .ant-menu .ant-menu-item-active {
  background-color: var(--grey-ultra-light);

  &:hover {
    color: var(--text-primary);
  }
}

.ant-menu-submenu .ant-menu .ant-menu-item-selected {
  color: var(--text-primary);
  font-weight: bold;
}
