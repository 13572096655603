@import "~antd/dist/antd.min.css";
@import "fonts";
@import "color";
@import "variables";
@import "antAlert";
@import "antDropdownList";
@import "antSelectDropdown";
@import "antMenu";
@import "antPickerDropdown";
@import "antSelect";
@import "breakpoints";
@import "swipeable";

* {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
}

body {
  height: 100vh;
  font-size: var(--font-size);
  font-family: var(--font-family);
  letter-spacing: var(--letter-spacing);
  color: var(--main-color);
  background-color: var(--page-bg);
}

#root {
  height: 100%;
}

h1.ant-typography {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
}

h2.ant-typography {
  font-size: var(--fs-24);
  line-height: var(--lh-24);
}

h3.ant-typography {
  font-size: var(--fs-20);
  line-height: var(--lh-20);
}

h4.ant-typography {
  font-size: var(--fs-16);
  line-height: var(--lh-16);
}

p {
  margin: 0;
}

a,
.link {
  cursor: pointer;
  color: var(--primary);
  transition: color 0.2s ease-in;

  &:hover {
    color: var(--secondary);
  }
}

.vi-wrapper {
  height: 100%;
  background-color: var(--primary-bg);

  &--demoMode {
    padding-top: 70px;
  }

  &--impersonation {
    padding-top: 58px;
  }

  &--is-tech-working {
    padding-top: 58px;
  }

  &--impersonation.vi-wrapper--is-tech-working {
    padding-top: 116px;
  }

  @media (max-width: $md-width) {
    height: auto !important;

    &--impersonation {
      padding-top: 0 !important;
    }
  }
}

.vi-enabled {
  body {
    line-height: 1.15;
  }

  img,
  image,
  svg {
    filter: grayscale(1);
  }

  .ant-collapse-item {
    &:not(.shadow) {
      .ant-collapse-content {
        border: 1px solid var(--grey-middle);
        border-top: none;
      }
    }
  }

  .vi-wrapper {
    padding-top: 180px;

    &--demoMode {
      padding-top: 255px;
    }
  }

  &.font-size-medium .vi-wrapper {
    padding-top: 200px;

    &--demoMode {
      padding-top: 285px;
    }
  }

  &.font-size-large .vi-wrapper {
    padding-top: 220px;

    &--demoMode {
      padding-top: 315px;
    }
  }

  @media (max-width: $md-width) {
    &.font-size-medium .vi-wrapper,
    &.font-size-large .vi-wrapper,
    .vi-wrapper {
      padding-top: 0;
    }
  }

  &.theme-blue {
    /* stylelint-disable */
    img,
    image,
    svg {
      filter: sepia(100%) hue-rotate(170deg) saturate(150%);
    }
    /* stylelint-enable */
  }

  &.without-images {
    img,
    svg {
      display: none !important;
    }
  }

  * {
    box-shadow: none !important;
  }

  a,
  .link {
    &:hover {
      text-decoration: underline;
    }
  }

  &.letter-spacing-medium {
    input,
    button,
    textarea {
      letter-spacing: 0.05em !important;
    }
  }

  &.letter-spacing-large {
    input,
    button,
    textarea {
      letter-spacing: 0.15em !important;
    }
  }
}

.ant-collapse-content {
  background-color: var(--page-bg);
}

.ant-picker-footer {
  & .ant-btn-primary {
    padding-left: 22px;
    padding-right: 22px;
    border-width: 2px !important;
    background: var(--primary);
    color: var(--page-bg);
    border-color: var(--primary);
    border-radius: 32px;

    &:hover {
      background: var(--secondary);
      color: var(--page-bg);
      border-color: var(--secondary);
    }
  }
}

@media (max-width: $md-width) {
  .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-range-wrapper {
    min-width: auto !important;
  }
}
